import React from "react";
import Layout from "../components/layout";
import { ContactUsForm } from "../components/ContactUsForm";

const Contact = () => {
  return (
    <Layout pageTitle="Contato">
      <ContactUsForm></ContactUsForm>
    </Layout>
  );
};

export default Contact;
